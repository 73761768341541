import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends GenericService {

  constructor(http: HttpClient) {
    super(http, 'user');

    let subdominio = localStorage.getItem('subdominio');

  
    if (window.location.hostname.includes("dev") || window.location.hostname == 'localhost') {
      this.url_base = 'https://api-bkchat-dev.avalongestor.com.br/api';
    } else {
      if(subdominio == 'bksoft-financeiro'){
        this.url_base = 'https://api-bkchat.bksoft.com.br/api';
      } else {
        this.url_base = 'https://chatapi-aws.avalongestor.com.br/api';
      } 
    }

    //  this.url_base = 'https://chatapi-aws.avalongestor.com.br/api';

  }

  authenticate(params = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/authenticate`, params);
  }

  storeCrm(params = null): Observable<any> {
    return this.http.post(`${this.url_base}/${this.prefixo}/store/crm`, params);
  }

  updateCrm(id, params = null): Observable<any> {
    return this.http.put(`${this.url_base}/${this.prefixo}/update/crm/${id}`, params);
  }

  logout(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/sitema/logout`);
  }

  listUsuarioInstancias(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list/usuario/canal-atendimento`);
  }

  listByDepartamento(departamentoId): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/list-by-departamento/${departamentoId}`);
  }
}
