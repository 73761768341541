import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


export abstract class GenericService {

  url_base: string = environment.urlBase + '/api';

  production = environment.production;

  direito = JSON.parse(localStorage.getItem('direitos'));

  awsDominio: any[] = [
    "demo",
    "guidare",
    "ipec",
    "mkt-bkchat",
    "risecorp",
    "dtudoparacasa",
    "stoky",
    "bksoftcomercial",
    "luna",
    "descontologistademo",
    "mundialcard",
  ];


  protected prefixo: any;

  constructor(protected http: HttpClient, protected target: any) {
    let subdominio = localStorage.getItem('subdominio');

    localStorage.setItem('newVersion', JSON.stringify(this.awsDominio));
    

    if (window.location.hostname.includes("dev") || window.location.hostname == 'localhost') {
      this.url_base = 'https://api-bkchat-dev.avalongestor.com.br/api';
    } else {
      if(subdominio == 'bksoft-financeiro'){
        this.url_base = 'https://api-bkchat.bksoft.com.br/api';
      } else {
        this.url_base = 'https://chatapi-aws.avalongestor.com.br/api';
      }      
    }

    // this.url_base = 'https://chatapi-aws.avalongestor.com.br/api';

    this.prefixo = target;
  }

  index(page = null, request = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}`;

    if (page !== null) {
      url += '?page=' + page;

      if (request != null) {
        url += '&' + request;
      }
    } else {
      if (request != null && request != "") {
        url += '?' + request;
      }
    }


    return this.http.get(url);
  }

  grid(): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/get/grid`;
    return this.http.get(url);
  }

  filter(params = null, page = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/filter`;

    if (page !== null) {
      url += '?page=' + page;
    }

    return this.http.post(url, params);
  }

  paginate(page = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/list/paginate`;

    if (page !== null) {
      url += '?page=' + page;
    }

    return this.http.get(url);
  }

  show(id = null): Observable<any> {
    if (id != null) {
      return this.http.get(`${this.url_base}/${this.prefixo}/${id}`);
    } else {
      return undefined;
    }
  }

  dropDown(): Observable<any> {
    return this.http.get(`${this.url_base}/${this.prefixo}/get/dropdown`);
  }

  save(body, id = undefined): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}`;

    if (id == undefined || id == null) {
      // url += `?plataforma=financeiro`;
      return this.http.post(url, body);
    } else {
      url += `/${id}`;
      return this.http.put(url, body);
    }
  }

  store(params = null, app = null): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}`;

    if (app != null) {
      url += '?' + app
    }

    return this.http.post(url, params);
  }

  update(params = null, id = null, app = null, formData = false): Observable<any> {
    let url = `${this.url_base}/${this.prefixo}/${id}?`;

    if (app != null) {
      url += '&' + app
    }

    if (formData == true) {
      url += '&_method=PUT';
    }

    if (formData == false) {
      return this.http.put(url, params);
    } else {
      return this.http.post(url, params);
    }
  }

  destroy(id = null): Observable<any> {
    return this.http.delete(`${this.url_base}/${this.prefixo}/${id}`);
  }


  listCidades(): Observable<any> {
    return this.http.get(`${this.url_base}/pessoa/list/cidades`);
  }

  getCep(cep = null): Observable<any> {
    return this.http.get(`${this.url_base}/get/cep/${cep}`);
  }

  listTipoContato(): Observable<any> {
    return this.http.get(`${this.url_base}/list/tipo/contato`);
  }

  listTipoInteresse(): Observable<any> {
    return this.http.get(`${this.url_base}/list/tipo/interesse`);
  }

  listCanalAtendimento(): Observable<any> {
    return this.http.get(`${this.url_base}/list/canal-atendimento`);
  }

  listAreaInteresse(): Observable<any> {
    return this.http.get(`${this.url_base}/list/area-interesse`);
  }

  listTipoPublicacao(): Observable<any> {
    return this.http.get(`${this.url_base}/list/tipo-publicacao`);
  }

  listAnos(): Observable<any> {
    return this.http.get(`${this.url_base}/list/anos`);
  }

  listUf(): Observable<any> {
    return this.http.get(`${this.url_base}/list/uf`);
  }

  listMunicipiosADM(): Observable<any> {
    return this.http.get(`${this.url_base}/list/municipios-adm`);
  }

  listUfLiberado(): Observable<any> {
    return this.http.get(`${this.url_base}/uf/liberado`);
  }

  listMunicipioUf(uf = null): Observable<any> {
    return this.http.get(`${this.url_base}/list/municipio-uf/${uf}`);
  }

  listLog(): Observable<any> {
    return this.http.get(`${this.url_base}/seguranca/log-sistema`);
  }


  direitos(key: string) {
    let existe;

    existe = this.direito.find(item => item.flag == key)


    if (existe != undefined) {
      return true
    } else {
      return false
    }

  }

  upload_s3(params: any): Observable<any> {
    return this.http.post(`${this.url_base}/service/upload`, params);
  }


  uploadAlis(params): Observable<any> {
    return this.http.post(`https://geracte.gridwebti.com.br/api/planilhas/upload`, params);
 }

}
