import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { DespesaReceitaService } from "src/app/services/despesa-receita.service";
import { PessoaService } from "src/app/services/pessoa.service";
import { PlanoContaService } from "src/app/services/plano-conta.service";
import * as moment from "moment";
import { FormaPagamentoService } from "src/app/services/forma-pagamento.service";
import { ContaService } from "src/app/services/conta.service";
import { AeronaveService } from "src/app/services/aeronave.service";
import { environment } from "src/environments/environment";
import { forkJoin } from "rxjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import * as FileSaver from "file-saver";

@Component({
    selector: "app-despesa-receita",
    templateUrl: "./despesa-receita.component.html",
    styleUrls: ["./despesa-receita.component.scss"],
})
export class DespesaReceitaComponent implements OnInit {
    dataList: any[] = [];

    modal: boolean = false;

    @Input() rota;

    selectionMode = false;

    cols: any[] = [
        { field: "id", header: "ID" },
        { field: "link_asaas", header: "Possui cobrança no asaas" },
        { field: "pessoa", header: "Pessoa" },
        { field: "vencimento", header: "Vencimento" },
        { field: "numero_documento", header: "N° Documento" },
        { field: "pago", header: "Pago" },
        { field: "valor", header: "Valor" },
        { field: "tipoCobrancaAsaas", header: "Tipo de cobrança" },
        { field: "situacao_real", header: "Situação" },
    ];

    colsFilesTable = [
        { field: "nome", header: "Nome" },
    ];

    uploadFile;

    dropdownTipoRepeticao: any[] = [
        { value: 7, label: "Semanal" },
        { value: 15, label: "Quinzenal" },
        { value: 30, label: "Mensal" },
        { value: 360, label: "Anual" },
    ];

    totalRecords: number = 0;

    form: FormGroup;

    files = [];

    typeFile;

    filesGrid = [];

    formFiltro: FormGroup;
    formBaixa: FormGroup;

    pt: any = {
        firstDayOfWeek: 0,
        dayNames: [
            "Domingo",
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
        monthNames: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        monthNamesShort: [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
    };

    urlStorage = environment.urlStorage;

    filteredPessoas: any[] = [];

    dropdownPlanoConta: any[] = [];

    dropdownPago: any[] = [
        { value: null, label: "Selecione" },
        { value: false, label: "Não" },
        { value: true, label: "Sim" },
    ];

    dropdownFormaPagamento = [];

    dropdownPeriodo = [
        { value: null, label: "Selecione" },
        { value: 7, label: "7 dias" },
        { value: 15, label: "15 dias" },
        { value: 30, label: "1 Mês" },
        { value: 92, label: "3 Meses" },
        { value: 182, label: "6 Meses" },
        { value: 365, label: "1 Ano" },
        // { value: undefined, label: "Informar" },
    ];

    dropdownStatus = [
        { value: "ABERTO", label: "Aberto" },
        { value: "PAGO", label: "Pago" },
        { value: "ATRASADO", label: "Atrasado" },
        { value: "CANCELADO", label: "Cancelado" },
        { value: "TODOS", label: "Todos" },
    ];

    tipo;

    rangeData = [];

    direitoInserir: boolean = false;

    direitoAlterar: boolean = false;

    linkToPDF;

    selectedTitulo;

    modalPDF = false;

    pdfUrls = ["https://sandbox.asaas.com/b/pdf/2532360909807239"];

    direitoReceita = true;

    direitoDespesa = true;

    configuracao = JSON.parse(localStorage.getItem("configuracao"));

    estornoInLoteMode = false;

    modalPagamento = false;

    constructor(
        private router: Router,
        private _formBuilder: FormBuilder,
        private _despesaReceitaService: DespesaReceitaService,
        private _pessoaService: PessoaService,
        private _planoContasService: PlanoContaService,
        private _formaPagamentoService: FormaPagamentoService,
        private _contaService: ContaService,
        private _aeronaveService: AeronaveService
    ) { }

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            id: [null],
            vencimento: [null],
            valor: [null],
            numero_documento: [null],
            repeticoes: [null],
            repetir: [null],
            plano_conta_id: [null],
            intervalo: [null],
            pessoa: [null],
            pago: [null],
            forma_pagamento_id: [null],
            data_pagamento: [null],
            percentual_juros: [null],
            percentual_multa: [null],
            cancelado: [false],
        });

        this.formFiltro = this._formBuilder.group({
            pessoa: [null],
            periodo: [365],
            plano_conta_id: [null],
            pago: [null],
            vencimentoRange: [null],
            status: ["TODOS"],
            cobranca_asaas: [true],
        });

        this.formBaixa = this._formBuilder.group({
            id: [null],
            data_pagamento: [null],
            pago: [null],
            forma_pagamento_id: [null],
        });

        this.changePeriodoVencimento();

        this.loadLazy();

        if (this.rota == "financeiro/despesa") {
            this.tipo = "DESPESA";
            this.direitoInserir =
                this._contaService.direitos("INSERIR_DESPESA");
            this.direitoAlterar =
                this._contaService.direitos("ALTERAR_DESPESA");
            this.listPlanoContaDespesa();
        } else {
            this.tipo = "RECEITA";
            this.direitoInserir =
                this._contaService.direitos("INSERIR_RECEITA");
            this.direitoAlterar =
                this._contaService.direitos("ALTERAR_RECEITA");
            this.listPlanoContaReceita();
        }

        this.listFormaPagamento();
    }

    changePeriodoVencimento() {
        console.log("rage");

        this.rangeData = [];

        if (this.formFiltro.get("periodo").value != undefined) {
            let currentDate = new Date();

            let endDate = currentDate;

            let startDate = new Date().setDate(
                currentDate.getDate() - this.formFiltro.get("periodo").value
            );

            this.rangeData = [startDate, endDate];
        }
    }

    listFormaPagamento() {
        this._formaPagamentoService.index().subscribe((formasPagamentos) => {
            let formaPagamentos;

            if (this.rota == "financeiro/despesa") {
                formaPagamentos = formasPagamentos.filter((f) => {
                    return (f.saida == true && f.conta_id != null);
                });
            } else {
                formaPagamentos = formasPagamentos.filter((f) => {
                    return (f.entrada == true && f.conta_id != null);
                });
            }

            this.dropdownFormaPagamento = formaPagamentos.map((f) => {
                return {
                    value: f.id,
                    label: `${f.descricao} - ${f?.conta?.banco?.nome} - AG: ${f?.conta?.agencia} CC:${f?.conta?.conta}-${f?.conta?.digito_conta}`,
                };
            });

        });
    }

    estornarTitulo(event) {
        this._despesaReceitaService.reversal(event.id).subscribe(json => {
            this.loadLazy();
        }, err => {
            alert(err.error.message)
        })
    }

    onUpload(event) {
        this.uploadFile = event;
    }

    loadLazy(event = null) {
        let page;

        let body: any = {};

        let tags = [];

        if (event == null) {
            page = 1;
        } else {
            page = event["first"] / event["rows"] + 1;
        }

        let apelido: any;

        let row = 100;

        let resquest;

        if (this.rota == "financeiro/despesa") {
            resquest = "tipo=DESPESA";
        } else {
            resquest = "tipo=RECEITA";
        }

        let formFiltro = this.formFiltro.value;

        if (formFiltro.pessoa != null) {
            resquest += `&pessoa=${formFiltro.pessoa}`;
        }

        if (formFiltro.plano_conta_id != null) {
            resquest += `&plano_contas_id=${formFiltro.plano_conta_id}`;
        }

        if (formFiltro.pago != null) {
            resquest += `&pago=${formFiltro.pago}`;
        }

        if (formFiltro.status != null) {
            resquest += `&status=${formFiltro.status}`;
        }

        if (formFiltro.cobranca_asaas && this.rota != "financeiro/despesa") {
            resquest += `&cobranca_asaas=sim`;
        } else {
            resquest += `&cobranca_asaas=nao`;
        }

        if (this.rangeData.length > 0) {
            let vencimentoInicio = moment(this.rangeData[0]).format(
                "YYYY-MM-DD"
            );
            resquest += `&vencimentoInicio=${vencimentoInicio}`;

            let vencimentoFim = moment(this.rangeData[1]).format("YYYY-MM-DD");
            resquest += `&vencimentoFim=${vencimentoFim}`;
        } else {
            if (formFiltro.vencimentoRange != null) {
                if (formFiltro.vencimentoRange[0] != null) {
                    let vencimentoInicio = moment(
                        formFiltro.vencimentoRange[0]
                    ).format("YYYY-MM-DD");
                    resquest += `&vencimentoInicio=${vencimentoInicio}`;
                }

                if (formFiltro.vencimentoRange[1] != null) {
                    let vencimentoFim = moment(
                        formFiltro.vencimentoRange[1]
                    ).format("YYYY-MM-DD");
                    resquest += `&vencimentoFim=${vencimentoFim}`;
                }
            }
        }

        this._despesaReceitaService
            .index(page, resquest)
            .subscribe((titulos) => {
                this.totalRecords = titulos.total;

                this.dataList = titulos.data;

                console.log('dataList>>>>', this.dataList);

                if (this.dataList != null && this.dataList.length > 0) {
                    this.dataList.map((item) => {

                        item['tipoCobrancaAsaas'] = item.codigo_carne_asaas != null ? 'Carnê' : 'Individual';

                        item.vencimento = moment(item.vencimento)
                            .utc(false)
                            .format("DD/MM/YYYY");
                        item.pago = item.pago ? "Sim" : "Não";

                        if (item.codigo_asaas != null) {
                            item.link_asaas = "Sim";
                        } else {
                            item.link_asaas = "Não";
                        }
                    });
                }

            });
    }

    showCancelInLote() {
        this.selectionMode = true;
        this.estornoInLoteMode = false;
    }

    showEstornoInLote() {
        this.selectionMode = false;
        this.estornoInLoteMode = true;
    }

    printBoleto(rowData) {
        this.selectedTitulo = rowData;

        console.log(this.selectedTitulo);

        if (this.selectedTitulo['emissor_boleto'] == 0) {
            this._despesaReceitaService
                .getBoletoAsaas(rowData.id)
                .subscribe((invoice) => {
                    this.pdfUrls = [invoice["bankSlipUrl"]];
                    this.modalPDF = true;
                    this.printLink(invoice["bankSlipUrl"]);
                });
        } else {
            this._despesaReceitaService.getBoleto(this.selectedTitulo['emissor_boleto'], this.selectedTitulo['id']).subscribe(json => {
                this.printLink(`https://4sells-storage.s3.sa-east-1.amazonaws.com/arquivos-chats/${json.url}`);
            })
        }

    }
    printRecibo(rowData) {
        this.selectedTitulo = rowData;
        this._despesaReceitaService
            .getRecibo(rowData.id)
            .subscribe((invoice) => {
                this.printLink(invoice);
            });
    }

    async mergePDFs(pdfBytesArray: Uint8Array[]): Promise<Uint8Array> {
        const mergedPdf = await PDFDocument.create();

        for (const pdfBytes of pdfBytesArray) {
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(
                pdf,
                pdf.getPageIndices()
            );
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        }

        return mergedPdf.save();
    }

    printLink(url) {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
    }

    showDialogForm(item = null) {
        this.filesGrid = []
        this.form.reset();

        this.form.get("repeticoes").setValue(1);
        this.form.get("cancelado").setValue(false);

        if (this.rota == "financeiro/receita") {
            this.form
                .get("percentual_juros")
                .setValue(
                    parseFloat(this.configuracao.percentual_juros_contrato) || 0
                );
            this.form
                .get("percentual_multa")
                .setValue(
                    parseFloat(this.configuracao.percentual_multa_contrato) || 0
                );
        }

        if (item != null) {
            this._despesaReceitaService.show(item.id).subscribe((titulo) => {
                titulo.vencimento = moment(titulo.vencimento)
                    .utc(false)
                    .toDate();

                if (titulo.pago) {
                    titulo.data_pagamento = moment(titulo.data_pagamento)
                        .utc(false)
                        .toDate();
                }
                if (titulo["arquivos"] != null) {
                    titulo.arquivos.map((item) => {
                        this.filesGrid.push({
                            id: item.arquivo.id,
                            download:
                                "https://4sells-storage.s3.sa-east-1.amazonaws.com/arquivos-chats/" +
                                item.arquivo.path,
                            nome: item.arquivo.original_name,
                        });
                    });
                }

                this.form.patchValue(titulo);

                if (titulo.pago == true) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            });
        }

        this.modal = true;
    }

    listPlanoContaDespesa() {
        this.dropdownPlanoConta = [];
        this.dropdownPlanoConta.push({ value: null, label: "Selecione" });
        this._planoContasService.getForDespesas().subscribe((json) => {
            json.map((item) => {
                this.dropdownPlanoConta.push({
                    value: item.id,
                    label: item.nome,
                });
            });
        });
    }

    listPlanoContaReceita() {
        this.dropdownPlanoConta = [];
        this.dropdownPlanoConta.push({ value: null, label: "Selecione" });
        this._planoContasService.getForReceitas().subscribe((json) => {
            json.map((item) => {
                this.dropdownPlanoConta.push({
                    value: item.id,
                    label: item.nome,
                });
            });
        });
    }

    save() {
        let params = this.form.value;

        let tipo;

        if (this.rota == "financeiro/despesa") {
            tipo = "DESPESA";
        } else {
            tipo = "RECEITA";
        }

        let requests = [];

        let vencimento = new Date(params.vencimento);

        if (params.repetir == true && params.repeticoes > 0) {
            for (let index = 1; index <= params.repeticoes; index++) {
                let body = {};

                body = {
                    pessoa_id: params.pessoa.id,
                    vencimento: moment(vencimento).format("YYYY-MM-DD"),
                    plano_contas_id: params.plano_conta_id,
                    valor: params.valor,
                    numero_documento: params.numero_documento,
                    pago: params.pago,
                    tipo: tipo,
                    forma_pagamento_id: params.forma_pagamento_id,
                    data_pagamento: params.data_pagamento,
                    percentual_multa: params.percentual_multa,
                    percentual_juros: params.percentual_juros,
                };

                requests.push(this._despesaReceitaService.store(body));

                if (params.intervalo == 360) {
                    let year = vencimento.getFullYear();
                    vencimento.setFullYear(year + 1);
                } else if (params.intervalo == 30) {
                    let month = vencimento.getMonth();
                    vencimento.setMonth(month + 1);
                } else {
                    let days = vencimento.getDate();
                    vencimento.setDate(days + params.intervalo);
                }
            }
        } else {
            let body = {
                pessoa_id: params.pessoa.id,
                vencimento: moment(vencimento).format("YYYY-MM-DD"),
                plano_contas_id: params.plano_conta_id,
                valor: params.valor,
                numero_documento: params.numero_documento,
                pago: params.pago,
                tipo: tipo,
                forma_pagamento_id: params.forma_pagamento_id,
                data_pagamento: params.data_pagamento,
                percentual_multa: params.percentual_multa,
                percentual_juros: params.percentual_juros,
            };

            requests.push(
                this._despesaReceitaService.save(
                    body,
                    this.form.get("id").value
                ).subscribe(json => {
                    this.files.map((file) => {
                        let formData = new FormData();
                        formData.append("myFile", file.file);
                        formData.append("id", json.id);
                        this._despesaReceitaService.uploadFile(formData).subscribe();
                    });
                })
            );
        }

        forkJoin(requests).subscribe((json) => {
            this.loadLazy();
            this.modal = false;
        });
    }

    addFileGrid() {
        this.files.push({
            file: this.uploadFile,
            nome: this.uploadFile.name,
        });

        this.filesGrid = [
            ...this.filesGrid,
            { nome: this.uploadFile.name },
        ];

        this.uploadFile = undefined;
    }

    filterPessoa(event) {
        this._pessoaService.autoCompleteAll(event.query).subscribe((json) => {
            this.filteredPessoas = json;
        });
    }

    exportExcel() {
        let data: any[] = [];
        this.dataList.map((item) => {
            data.push({
                id: item.id,
                possui_cobranca_asaas: item.link_asaas,
                pessoa: item.pessoa,
                vencimento: item.vencimento,
                numero_documento: item.numero_documento,
                pago: item.pago,
                valor: item.valor,
            });
        });

        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            this.saveAsExcelFile(excelBuffer, this.tipo);
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        FileSaver.saveAs(
            data,
            this.tipo + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
    }

    cancelInLote() {
        this.selectionMode = false;

        if (this.selectedTitulo.length == 0) {
            return;
        }

        let titulos = this.selectedTitulo.map((t) => {
            return t.id;
        });

        this.selectedTitulo = [];

        this._despesaReceitaService
            .removeCobrancaInLote(titulos)
            .subscribe((json) => {
                this.loadLazy();
            });
    }

    estornoInLote() {
        this.estornoInLoteMode = false;

        if (this.selectedTitulo.length == 0) {
            return;
        }

        let titulos = this.selectedTitulo.map((t) => {
            return t.id;
        });

        this.selectedTitulo = [];

        this._despesaReceitaService
            .estornoCobrancaInLote(titulos)
            .subscribe((json) => {
                this.loadLazy();
            });
    }

    showBaixarTitulo(row) {
        this.formBaixa.reset();
        this.modalPagamento = true;
        this._despesaReceitaService.show(row.id).subscribe(json => {

            if (json.data_pagamento) {
                json.data_pagamento = moment(json.data_pagamento)
                    .utc(false)
                    .toDate();
            }
            this.formBaixa.patchValue(json);
            this.formBaixa.get('pago').setValue(true);
        })
    }

    liquidate() {
        let body = this.formBaixa.value;
        this._despesaReceitaService.liquidate(body).subscribe(json => {
            this.loadLazy();
            this.modalPagamento = false;
        })
    }
}
